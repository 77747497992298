
import * as React from "react";
import axios from "axios";
import ReactHtmlParser from "html-react-parser";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect, navigate } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import iconoFlechaElevva from "../assets/newIcons/ICO-FLECHA-ELEVVA.svg";

import "react-widgets/styles.css";

//spinner
import { BarLoader, HashLoader } from "react-spinners";
// modal bootstrap
import { Modal } from "react-bootstrap";

import SwiperCore, { Virtual, Navigation, Pagination } from "swiper";

import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper.min.css";

import { decodeJwt } from "jose";

//mport Slider from "../components/inicio/Slider";
import { Multiselect } from "react-widgets";
import "./utils.css";
// import { RtcRole } from "agora-access-token";




export const laptop575 = window.matchMedia("(min-width: 921px)");
export const laptop1600 = window.matchMedia("(min-width: 1650px)");
export const laptop1350 = window.matchMedia("(min-width: 1350px)");
export const laptop1300 = window.matchMedia("(min-width: 1450px)");
// export const NuevaAxiosURL = `https://api.elevva.com.co/api/v1/`; // Enviando datos a Elevva API

export const NuevaAxiosURL = process.env.REACT_APP_URL_SERVER + `/api/v1/`; // Enviando datos a Staging API
export const urlImages = process.env.REACT_APP_URL_SERVER
export const basename = process.env.NODE_ENV == "development" ? `` : "";
export const instance = axios.create({
  baseURL: NuevaAxiosURL,

  params: {
    t: new Date().getTime(),
  },
});
export const tawkToPropertyId = "5efb722a4a7c6258179ba1b3";
export const tawkToKey = "default";
export const userType = "Usuario";


// Iniciar la conexión

instance.interceptors.request.use(async function (response) {
  let refresh = "users/auth/login/refresh";
  // const userSelected = response.data // avatar
  if (localStorage.my_code) {
    const { exp } = decodeJwt(localStorage.my_code);
    // console.log(Date.now(), exp * 1000);
    // var tiempoexp = exp * 1000 - 3580000;
    var tiempoexp = exp * 1000 - 35000;
    var tiempoahora = Date.now();
    // console.log(tiempoahora - tiempoexp);
    // console.log("refresh", localStorage.refresh);
    // console.log("accces", localStorage.my_code);
    if (tiempoahora >= tiempoexp) {
      var formData = new FormData();
      formData.append("refresh", localStorage.refresh);
      await axios({
        method: "post",
        contentType: "application/json",
        url: `${NuevaAxiosURL}${refresh}`,
        data: formData,
        // firts_name: data_user.first_name, // avatar
        // last_name: data_user.last_name,  // avatar
      })
        .then((res) => {
          const data = res.data;
          // console.log("datarararefreshokoko---------------", data);
          if (data) {
            localStorage.my_code = data.access;
            localStorage.refresh = data.refresh;
          }
        })
        .catch((err) => {
          // localStorage.clear();
          window.location.href="/";
          // window.location.reload();
        });
      response.params["t"] = new Date().getTime();
      response.headers["Authorization"] = "Bearer " + localStorage.my_code;
      return response;
    } else {
      response.params["t"] = new Date().getTime();
      response.headers["Authorization"] = "Bearer " + localStorage.my_code;
      return response;
    }
  } else {
    response.params["t"] = new Date().getTime();
    response.headers["Authorization"] = "Bearer " + localStorage.my_code;
    return response;
  }
});

export function objEmpty(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}









export const ModalInfoVideosFilter = React.forwardRef((props, ref) => {


  const disciplinas=props.disciplinas;
  
  const variables_entrenamiento = {
    main_topic: ["Entrenamiento", "Nutrición"],
    "workout_zone": [
      
      "Tren inferior",
      "Abdomen",
      "Tren superior",
      "Todo el cuerpo",
      
    ],
    workout_equipment:["Mancuernas","Bandas cerradas de resistencia","Mat de yoga","Colchoneta","Muñequeras de peso","Fit ball / Balón de pilates","Bandas de resistencia con mangos","Guantes de boxeo","Lazo de salto","Set de pump","Caminadora eléctrica","Bicicleta estática","Implementos del hogar","Sin equipos peso corporal"],
    discipline:["Yoga","Funcional","Fuerza","Balance","Bajo impacto","Cardio funcional","HIIT","Pilates","Nutrición","Mente"],
    skill_level: ["Bajo", "Medio", "Alto"],
    workout_type: ["Cardiovascular", "Fuerza", "Movilidad", "Flexibilidad"],
    workout_time: ["Entre 10 a 30 minutos", "Entre 30 a 60 minutos", "Más de 60 minutos"],
 

  };
 

  const title = props.title;

  const [variables, setvariables] = useState(variables_entrenamiento);
  const text = props.text || "none";

  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "fixed";
  const handleCancel = props.handleCancel;

  function handleClick(event) {
    setvariables(variables_entrenamiento);
    props.handleClick();
  }
  function changeselect(target) {
    props.handleInputCategory(target);
  
      setvariables(variables_entrenamiento);
    
  }
  function handleChange(e) {
    console.log(e)
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  const path = useLocation();

  const classesMsg = props.classesMsg || null;
  const Rendershit = ({ e, handleChange, index, variables }) => {
    return (
      <Multiselect
        name={e[0]}
        placeholder="Selecciona"
        textField={e[0]}
        data={variables[`${e[0]}`]}
        onChange={(nextValue) => handleChange({ [`${e[0]}`]: nextValue })}
      />
    );
  };
  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center "
      style={styleModal}
    >
      <div className={"modalInfoContenidovideos"}>
       
        <div
          className={
            path.pathname.includes("Entrenador/clase")
              ? title == "Lesionado"
                ? classesMsg + " text-left text colornuevaletra_entre my-3"
                : classesMsg + " text"
              : title == "Lesionado"
              ? classesMsg + " text-left text colornuevaletra my-0"
              : classesMsg + " text"
          }
          style={{ display: displayText }}
        >
          {text}
        </div>
        {variables &&
          Object.entries(variables).map((e, index) => (
            <div key={index}  style={{ color: "#394F65",textAlign:"left" }}>
              {e[0] == "main_topic" ? (
                <div className="my-1">
                  <span>Tema</span>
                 
                </div>
              )
              :e[0] == "workout_zone" ? (
                <div className="my-1">
                  <span>Zona a </span>
                  <span>
                    <strong> trabajar</strong>
                  </span>
                </div>
              ) : e[0] == "workout_type" ? (
                <div className="my-1">
                  <span>Tipo</span>
                  <span>
                    <strong> de entrenamiento</strong>
                  </span>
                </div>
              ) : e[0] == "discipline" ? (
                <div className="my-1">
                  <span>Disciplina</span>
                </div>
              ) : e[0] == "workout_equipment" ? (
                <div className="my-1">
                  <span>Equipo para</span>
                  <span>
                    <strong> entrenar</strong>
                  </span>
                </div>
              ) :  e[0] == "skill_level" ? (
                <div className="my-1">
                  <span>Nivel de</span>
                  <span>
                    <strong> difícultad</strong>
                  </span>
                </div>
              ) :(
                <div className="my-1"> Tiempo</div>
              )}
              {variables ? (
                <Rendershit
                  e={e}
                  index={index}
                  handleChange={handleChange}
                  variables={variables}
                />
              ) : null}
            </div>
          ))}
        <div className=" botonesModalInfo my-2 col-12">
          {laptop575.matches ? <div className="col-4"></div> : null}
          {props.handleClick ? (
            <ButtonMob
              text="Remover filtros"
              colorButton={"transparent"
              }
              borderbutton={`1px solid var(--elevva)`}
              colorTextButton={`var(--elevva)`}
              className={
                laptop575.matches
                  ? "btnAction butonvideobannerfilter px-2  py-2 col-3 mx-1"
                  : "btnAction butonvideobannerfilter px-2  py-2 col-7 mx-1"
              }
              handleClick={() => {
                handleClick();

                let boxes =
                  document.getElementsByClassName("rw-multiselect-tag");
                let place = document.getElementsByClassName(
                  "rw-multiselect-input"
                );

                for (let i = 0; i < place.length; i++) {
                  place[i].placeholder = "Selecciona";
                  place[i].size = "11";
                  setvariables(variables_entrenamiento)
                }
                while (boxes.length > 0) {
                  for (let i = 0; i < boxes.length; i++) {
                    boxes[i].remove();
                    // loscreados.appendChild(paragraph);
                  }
                // setvariables(variables_entrenamiento)
                }
              }}
            />
          ) : null}

          {handleCancel ? (
            <ButtonMob
              text="Cerrar"
              className="btnActionCancelar butonvideobannerfilter py-2 col-3"
              handleClick={() => handleCancel()}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
});
// Axios rutas
export const checkPathNavFoot = (path, type) => {
  return (
    path == `/` ||
    path.includes(type) ||  path.includes(`Onboarding`) || path.includes(`/TutorialReserva/`) ||
    path.includes(`Entrenador`)||  path.includes(`recuperar`)||
    path.includes(`Completar`) ||  path.includes(`Share`)||  path.includes(`Corporativo`) 
  );
};
export const checkPathNavFootMobile = (path, type) => {
  return (
    path == `/` || path.includes(`recuperar`) ||
    path.includes(type) ||   path.includes(`/trainerInfo/`) ||   path.includes(`/actividadApp`)  ||  path.includes(`/selectGrupalList/`) ||  path.includes(`/TutorialResponsive/`) ||  path.includes(`/selectSchedule/`)  ||  path.includes(`Onboarding`) ||   path.includes(`/training/`) ||  path.includes(`/selectTeacher/`)  || path.includes(`Entrenador`)|| path.includes(`Completar`) ||  path.includes(`/ScheduleMobile/`) ||  path.includes(`Share`) );
};

export const notInPublic = (path) => {
  return (
    path == `/` ||
    path == "/tipos_entrenamientos" ||
    path == "/lineamientos" ||
    path == "/beneficios" ||
    path == "/servicios" ||  
    path == "/como_funciona"
  );
};
export  const TitleRow = (props) => {
  const infoTraining = props.infoTraining;
  const handleclick=props.handleclick;
  return(
    <div 
            
            className="w-70 w-md-100 ubication_arrow_title"
          >
            <div className="d-flex my-auto ">
              <div
                onClick={handleclick}
                style={{
                  background: "transparent",
                  border: "1px solid #A8C2D7",
                  padding: "1vmin",

                  height: "fit-content",
                  cursor: "pointer",
                  color: "#A8C2D7",
                  borderRadius: "10px",
                }}
              >
                <span style={{ fontSize: "1rem" }}>
                  <i className="bi bi-chevron-left"></i>
                </span>
              </div>
              <div className=" my-auto mx-2 d-flex flex-column gap-2 justify-content-center ">
                <span
                  className="mt-2  text_arrow_top"
               
                >
                  Entrenamiento
                </span>
                <span
                  style={{
                    color: "#394F65",
                    fontWeight: "bold",
                    lineHeight: "100%",
                    fontSize: "calc(1.3rem + 0.3vw)",
                  }}
                >
                  Clase de <br />
                  {infoTraining &&infoTraining.name}
                </span>{" "}
                {infoTraining?.credits == 0 ? (
                  <button
                    style={{
                      background: "var(--elevva)",
                      border: "1px solid var(--elevva)",
                      borderRadius: "20px",
                      color: "var(--white)",
                      fontWeight: "300",
                      maxWidth: "60%",
                      padding: "1% 0%",
                    }}
                  >
                    GRATIS
                  </button>
                ) : null}
              </div>
            </div>
          </div>
  )
}



export const LoginUtil = (props) => {
  const navigate = useNavigate();
  const elevvaT = props.elevvaT;
  const logoElevva = props.logoElevva;
  const NameCustomer = elevvaT("cover_start_login_customer");
  const NameTrainer = elevvaT("cover_start_login_trainer");

  return (
    <div className="m-auto text-center ">
      <img src={logoElevva} className="logoElevva_size" />
      <div>
        <label className="labelLogin mt-2">
          {elevvaT("cover_start_login")}
        </label>
        <ButtonMob
          handleClick={() => navigate("/" + NameCustomer)}
          text={NameCustomer}
        ></ButtonMob>
        <ButtonMob
          colorButton={`var(--elevvabutonLogin)`}
          handleClick={() => navigate("/" + NameTrainer)}
          colorTextButton={`var(--elevvaTextLogin)`}
          text={NameTrainer}
        ></ButtonMob>

        <p className="labelLoginLaws">{elevvaT("law_reserved_elevva")}</p>
      </div>
    </div>
  );
};

export const ModalInfo1 = React.forwardRef((props, ref) => {
  const title = props.title;
  const text = props.text || "none";
  const textDelete = props.textDelete;
  const icon = props.icon || "bi bi-check-circle-fill";
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "fixed";
  const handleCancel = props.handleCancel;
  function handleClick(event) {
    props.handleClick();
  }
  function handleClose(event) {
    props.handleClose();
  }
  function handleDelete(event) {
    props.handleDelete(event);
  }
  function handleInput(e) {
    props.handleInput(e);
   
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };

  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  const classesMsg = props.classesMsg || null;
  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div className="modalInfoContenido">
        <div className="icon my-3" style={{ display: displayError }}>
          {error == "false" ? (
            <i className={icon}></i>
          ) : (
            <i className={icon}></i>
          )}
        </div>

        <div
          onClick={handleClose}
          style={{
            position: "absolute",
            right: "6%",
            top: "2vmin",
            color: "#969696",
            fontWeight: "700",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          <i className="bi bi-x-circle"></i>
        </div>
        <div
          className="text-color-title"
          style={{
            textAlign: "left",
            fontSize: "1.5rem",
            maxWidth: "20vmin",
            lineHeight: "1.1",
            fontWeight: "bold",
          }}
        >
          {title}
        </div>

        <div className={classesMsg + " text"} style={{ display: displayText }}>
          {text}
        </div>
        <textarea
          placeholder="Describe aquí tu lesión."
          className="placeholder_onboarding"
          style={{
            display: displayInput,
          }}
          autoFocus
          rows="5"
          onInput={handleInput}
        ></textarea>
        <div className={textDelete ? "d-row" : "botonesModalInfo"}>
          <ButtonMob
            colorButton={`var(--elevvabutonLogin_User)`}
            text="Guardar lesión"
            className="btnAction1"
            handleClick={handleClick}
          />
          {textDelete && window.location.pathname.includes(`/perfil`) || textDelete && window.location.pathname.includes(`/MisLesiones`) ? (
            <ButtonMob
              text="Borrar lesión"
              className="btnAction1 btn-danger"
              handleClick={handleDelete}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
});

export const LoginComponentUser = (props) => {
  const elevvaT = props.elevvaT;
  const navigate = useNavigate();

  const type = props.type;
  const [displayError, setDisplayError] = useState("none");

  const [recover, setRecover] = useState(false);
  const [displayLoading, setDisplayLoading] = useState(null);
  const [autenticado, setautenticado] = useState("norefrescado");
  const [modalInfo, setModalInfo] = useState(null);

  const handleSubmit = (event) => {
    const usuario = event.target[0].value.trim();
    const password = event.target[1].value.trim();
    const servicio = "users/auth/login/";

    setDisplayLoading(
      <div className="spinner-container-login">
        <HashLoader color="#8d97fd" />
      </div>
    );

    const formData = new FormData();
    formData.append("email", usuario);
    formData.append("password", password);
   

    axios({
      method: "post",
      contentType: "application/json",
      url: `${NuevaAxiosURL}${servicio}`,
      params: {
        t: new Date().getTime(),
      },
      data: formData,
    })
      .then(async(res) => {
        const data = res.data;
        if (data) {
          if (autenticado == "norefrescado") {
            localStorage.my_code = data.access;
            localStorage.refresh = data.refresh;
            localStorage.type = type;
           

            //localStorage.setItem('my_code', data.access);
            //localStorage.setItem('refresh', data.refresh);
            //localStorage.setItem('type', type);

            const instanceLogin = axios.create({
              baseURL: NuevaAxiosURL,
              params: {
                t: new Date().getTime(),
              },
              headers: { Authorization: "Bearer " + data.access },
            });

          await instanceLogin.get("users/").then((response) => {
              let data_user = response.data;
              localStorage.first_name = data_user.first_name;
              localStorage.last_name = data_user.last_name;
              localStorage.email = data_user.email;
              localStorage.id = data_user.id;
              localStorage.profile_pic = data_user.customer.profile_pic;
              if(data_user.membership) localStorage.membership = data_user.membership.membership.id

              if (data_user.type == 20 && type == "Entrenador") {
                if (data_user.trainer.is_enabled) {
                  alert('page not created')
                  // document.location.href = `${basename}/${type}/inicio`;
                } else {
                  setModalInfo(
                    <ModalInfo
                      error="none"
                      handleClick={() =>
                        localStorage.clear() ||
                        (document.location.href = `${basename}/`)
                      }
                      title="Hola entrenador"
                      text={
                        "Estamos verificando la documentación, en poco tiempo serás parte de nuestro equipo"
                      }
                      classesMsg=""
                    />
                  );
                }
              } else if (data_user.type == 10 ) {
                document.location.href = `${basename}/Dashboard`;
              } else {
                localStorage.clear();

                setModalInfo(
                  <ModalInfo
                    error="none"
                    handleClick={() =>
                      (document.location.href = `${basename}/`)
                    }
                    title="Error de ingreso"
                    text={"Tu tipo de ingreso es incorrecto"}
                    classesMsg=""
                  />
                );
              }
            });
          }
        }
      })
      .catch((err) => {
        if (err.response) {
        
          localStorage.clear();
          setDisplayError("inherit");
          setTimeout(() => {
            setDisplayError("none");
          }, 2000);
          setDisplayLoading(null);
        }
      });

    event.preventDefault();
  };

  const FormLogin = () => {
    const [actualIconEye, setActualIcon] = useState("bi bi-eye-fill");
    const [showOrNo, setShow] = useState("password");
    const handleShowPass = () => {
      if (actualIconEye == "bi bi-eye-fill") {
        setActualIcon("bi bi-eye-slash-fill");
        setShow("text");
      } else {
        setActualIcon("bi bi-eye-fill");
        setShow("password");
      }
    };
    return (
      <div className="total-width-login">
        <h1 className=" m-0">{elevvaT("welcome")}</h1>
        <p className="text-color-subtitle">{elevvaT("label_welcome_down")}</p>
        <form
          className="LoginForm Registro"
          style={{
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            
          }}
          onSubmit={(e) => handleSubmit(e)}
        >
          <input
            type="email"
            placeholder={elevvaT("email_text")}
            name="mail"
            id={props.idMail}
            className="form-control input-style-elevva mx-auto"
            required
          ></input>
          <div
            id="passmail_error"
            className="errorRequest"
            style={{
              display: displayError,
              color: "#CB3D82",
              fontWeight: "400",
            }}
          >
            Correo y/o contraseña incorrectas.{" "}
          </div>

          <div className="inputs-inside position-relative">
            <input
            id={props.idPassword}
              type={showOrNo}
              placeholder={elevvaT("password_text")}
              name="pass"
              autoComplete="on"
              className="form-control input-style-elevva mx-auto"
              required
            ></input>
            <div className="eye-color-location" onClick={handleShowPass}>
              <i className={actualIconEye}></i>
            </div>
          </div>
          <div
            id="passmail_error"
            className="errorRequest"
            style={{
              display: displayError,
              color: "#CB3D82",
              fontWeight: "400",
            }}
          >
            Correo y/o contraseña incorrectas.{" "}
          </div>
          {displayLoading}

          <div
            onClick={() => setRecover(true)}
            className="d-flex  justify-content-center "
          >
            <u className="text-color-elevva"> {elevvaT("forgot_password")}</u>
          </div>

          <div className="d-flex justify-content-center ">
            <ButtonMob
              className="m-0 buttonLoginStart"
              colorButton={`var(--elevvabutonLogin_User)`}
              text={elevvaT("log_in")}
            />
          </div>

          <div className=" d-flex justify-content-center text-color-subtitle">
            {elevvaT("you_new")}&nbsp;
            <Link to={`/createUser/${type}`}>
              <u className="text-color-elevva">{elevvaT("new_account_text")}</u>
            </Link>
          </div>
          <div id="text-w-line" className="text-color-subtitle">
            <span style={{ zIndex: "2" }}>Ó</span>
          </div>

          <ButtonMob  handleClick={() => navigate("/seleccionaConvenio/Usuario")}
            className="spacebuttonLogin my-0"
            colorButton={`var(--elevvabutonLogin_User)`}
            text={"Iniciar sesión con convenio"}
            
          />

          <div className=" d-flex justify-content-center text-color-subtitle text-center">
            ¿Tienes una cuenta de elevva con algún convenio?
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <div className="d-md-none mobile-login w-100 ">
        <div style={{ height: "calc(100vh - 65vh)" }}>
          <div
            className="containerBGClase"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/assets/img/welcome-login-old.jpg"
              })`,
              backgroundSize: "cover",
            }}
          >
            <div className="button-back-start">
              <ButtonArrow
                colorBack={`var(--elevvaArrowColor)`}
                handleClick={() => navigate(-1)}
              />
            </div>
          </div>
        </div>
        <div
          className="mobile-welcome-login-row  text-left w-100"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
           
            alignItems: "center",
           
          }}
        >
          <FormLogin idMail={'mailResponsive'}  idPassword={'passResponsive'}/>
        </div>
      </div>
      <div className=" d-none d-md-flex col-12 ">
        <div
          className="backgrounImgLoginStart"
          style={{
            width: "53%",
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/assets/img/welcome-login-old.jpg"
            })`,
          }}
        ></div>
        <div
          className=""
          style={{
            width: "50%",
            margin: "auto",
            zIndex: "2",
            height: "100%",
            position: "absolute",
            left: "50%",
            justifyContent: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <div className="box-Welcome-login text-left col-12 d-flex">
            <div className="w-100 mx-auto d-flex">
              <div className="total-width-arrow">
                <ButtonArrow
                  colorBack={`var(--elevvaArrowColor)`}
                  handleClick={() => navigate(-1)}
                />
              </div>
              <FormLogin idMail={'mailWeb'}  idPassword={'passWeb'} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export function perlo() {
  if (sessionStorage.onesignal_id) {
    let formData = new FormData();
    formData.append("refresh_token", localStorage.refresh);
    formData.append("onesignal_id", sessionStorage.onesignal_id);
    instance
      .post(`users/auth/logout/`, formData)
      .then((res) => {
        if (res.status == 205 || res.status == 200) {
          localStorage.clear();
          window.location.href = `/`;
        }
      })
      .catch((err) => {
        localStorage.clear();
        window.location.href = `/`;
      });
  } else {
    localStorage.clear();
    window.location.href = `/`;
  }
}
export const ButtonArrow = (props) => {
  const colorBack = props.colorBack;
  const buttonColor = props.buttonColor || `var(--elevvaArrowColor)`;
  const isDisabled = props.disabled || null;
  const icon = props.icon;
  const classes = `btnArrow ${props.classes}`;
  function handleClick() {
    props.handleClick();
  }
  return (
    <button
      disabled={isDisabled ? true : false}
      className={classes}
      style={{
        backgroundColor: "transparent",
        border: `1px solid ${colorBack}`,
        display: "flex",
      }}
      onClick={handleClick}
    >
      <i
        className={icon ? icon : "bi bi-chevron-left"}
        style={{
          color: colorBack,

          fontSize: "1.5rem",
          display: "inline-flex",
        }}
      ></i>
    </button>
  );
};
export const ButtonMob = (props) => {
  const text = props.text;
  const colorButton = props.colorButton || `var(--elevva)`;
  const borderbutton = props.borderbutton || ``;
  const colorTextButton = props.colorTextButton || `var(--white)`;
  const icon = props.icon || null;
  const sizeFont = props.sizeFont || "1rem";
  const isDisabled = props.disabled || null;
  const classes = `btnMOB ${props.className ? props.className : ""}`;
  const idRef=props.id

  function handleClick() {
    props.handleClick();
  }
  function handleClickCamera() {
    props.handleClickCamera();
  }

  return ( 
    <button id={idRef}
      disabled={isDisabled ? true : false}
      className={classes}
      style={{
        background: colorButton,
        color: colorTextButton,
        border: borderbutton,
        fontSize: sizeFont,
        cursor: "pointer",
      }}
      onClick={
        props.handleClick
          ? handleClick
          : props.handleClickCamera
          ? handleClickCamera
          : null
      }
    >
      {/* {image1 && laptop575.matches ? (
          <img src={image1} className={classesImg} alt="boton img" />
        ) : null}
        {image5 && laptop575.matches ? (
          <i className="fa fa-arrow-left corridoreservatuclase1"></i>
        ) : null} */}
      {icon && icon.includes("left") ? (
        <i className={`${icon} arrow-white-on-button`}></i>
      ) : null}
      {ReactHtmlParser(text)}
      {/* {image && !laptop575.matches ? (
          <img src={image} className={classesImg} alt="boton img" />
        ) : null}
  
        {image1 && laptop575.matches ? (
          <i className="fa fa-arrow-right corridoreservatuclase"></i>
        ) : null}
        {imageweb && laptop575.matches ? (
          <img src={imageweb} className={classesImg} alt="boton img" />
        ) : null} */}
      {icon && icon.includes("right") ? (
        <i className={`${icon} arrow-white-on-button`}></i>
      ) : null}
    </button>
  );
};
export const Loadingspinner = (props) => {
  const { t } = useTranslation();
  const size = props.size;
  const style = props.customStyle; // grow or border
  const classloading = props.classloading || null;
  const widthContain=props.widthContain || "auto"
  return (
  
      <div style={{width:widthContain,height:widthContain}}
        className={
         "d-flex justify-content-center align-items-center text-center m-auto"
        }
      >
        <div
          className={"imagenload "}
          role="status"
          style={{ width: size, height: size }}
        >
          <span className="visually-hidden">{t("loading_spinner")}</span>
        </div>
      </div>
   
  );
};

export const CustomModal = (props) => {
  const { show, handleClose, title, text, classesMsg, items } = props;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="d-flex justify-content-center">
        <i
          className="bi bi-x-circle-fill align-self-center"
          style={{ color: "#9EACED", fontSize: "3.5rem" }}
        ></i>
      </div>
      <Modal.Body>
        <div className="text-center">
          <Modal.Title>{title}</Modal.Title>
          <br />
        </div>
        <div className="d-flex justify-content-center">
          <h4 className={classesMsg}>{items}</h4>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <ButtonMob
            colorButton={`var(--elevva)`}
            className="mx-2 sizebuttonLarge"
            text={"Entendido"}
            handleClick={handleClose}
          />
          {/* <Button  onClick={handleClose}>
          Cerrar
        </Button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const CustomModalError = (props) => {
  const { show, handleClose, title, text, classesMsg, items } = props;
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="d-flex justify-content-center">
        <i
          className="bi bi-x-circle-fill align-self-center"
          style={{ color: "#9EACED", fontSize: "3.5rem" }}
        ></i>
      </div>
      <Modal.Body>
        <div className="text-center">
          {/* <Modal.Title>{title}</Modal.Title> */}
          <span
            className="m-auto"
            style={{
              fontSize: "2.5vmin",
              lineHeight: "100%",
              maxWidth: "35vmin",
            }}
          >
            {" "}
            <strong>Algo salio mal</strong>
          </span>
          <br />
        </div>
        <div className="modal-body">
          <p
            className="text-color-subtitle my-3 text-center"
            style={{
              lineHeight: "100%",
              // maxWidth: "35vmin",
            }}
          >
            Algo nos acaba de suceder, por favor intentalo nuevamente en unos
            minutos
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <ButtonMob
            colorButton={`var(--elevva)`}
            className="mx-2 sizebuttonLarge"
            text={"Entendido"}
            //handleClick={handleClose}
            handleClick={refreshPage}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const CustomModalOk = (props) => {
  const {
    show,
    handleClose,
    title,
    text,
    classesMsg,
    classesMsgs,
    sendDataFinal,
  } = props;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="d-flex justify-content-center">
        <i
          className="bi bi-check-circle-fill align-self-center"
          style={{ color: "#9EACED", fontSize: "3.5rem" }}
        ></i>
      </div>
      <Modal.Body>
        <div className="text-center">
          <Modal.Title>{title}</Modal.Title>
          <br />
          <span
            className={classesMsg}
            style={{
              color: "rgb(169, 182, 196)",
              fontWeight: "bold",
              fontsize: "1.5vmin",
            }}
          >
            {classesMsg}
          </span>
          <br />
          <span
            className={classesMsg}
            style={{
              color: "rgb(169, 182, 196)",
              fontWeight: "bold",
              fontsize: "1.5vmin",
            }}
          >
            {classesMsgs}
          </span>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <ButtonMob
            colorButton={`var(--elevva)`}
            className="mx-2 sizebuttonLarge"
            text={"Confirmar"}
            //handleClick={handleClose}
            // handleClick={() => {
            //     window.location.href = "/Usuario/Inicio"
            //   }}
            handleClick={sendDataFinal}
          />
          {/* <Button  onClick={handleClose}>
          Cerrar
        </Button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export const ModalInfoProcess = React.forwardRef((props, ref) => {
 
  return (
    <div
   
      className="modalInfo justify-content-center align-items-center d-flex"
     
    >
      <div
        className={
          "modalInfoContenido d-flex justify-content-center flex-column"
        }
      >
       <Loadingspinner customStyle="border" size="7vmin" widthContain={"7rem"}/>
       
      </div>
    </div>
  );
});
export const ModalInfo = React.forwardRef((props, ref) => {
  const title = props.title || "";
  const icono = props.icono;
  const text = props.text || "none";
  const textButtonAction = props.textButtonAction || "none";
  const backgroundReservarBorder = props.backgroundReservarBorder || "btnActionCancelar py-2";
  const loader=props.loader || false
  const array = props.array || null;
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "fixed";
  const handleCancel = props.handleCancel;
  const iconoWidth = props.iconoWidth || "12vmin";
  const textSizeUp = props.textSizeUp || "1rem";
  const textButtonConfirmar = props.textButtonConfirmar || "Confirmar"
  const sizeTitle = props.sizeTitle || null;
  
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const classcorrecta = props.classcorrecta || null;

  function handleReservar(event) {
    props.handleReservar();
  }
  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  const path = useLocation();

  const classesMsg = props.classesMsg || null;
  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div
        className={
          path.pathname.includes("Entrenador/clase")
            ? "modalInfoContenido_clase"
            : "modalInfoContenido"
        }
      >
        {error == "none" || error == "true" ? (
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div>
        ) : (
          <div
            
            className=""
            style={{
              position: "absolute",
              top: "6%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div>
        )}
        {icono ? (
          <img src={icono} style={{ width: iconoWidth, margin: "0.5rem 0 " }} />
        ) : null}
        {title == "Lesionado" ? null : (
          <div className="icon  p-2" style={{ display: displayError }}>
            {error == "false" ? (
              <i
                className="bi bi-x-circle-fill "
                style={{ color: "#9EACED",fontSize:'calc(3.0rem + 0.5vmin)' }}
              ></i>
            ) : (
              <i
                className="bi bi-check-circle-fill "
                style={{ color: "#CB3D82",fontSize:'calc(3.0rem + 0.5vmin)' }}
              ></i>
            )}
          </div>
        )}
        {classcorrecta ? (
          <div className={classcorrecta}>{ReactHtmlParser(title)} </div>
        ) : (
          <div style={sizeTitle?{fontSize:sizeTitle,fontWeight:'600'}:null}
            className={
              title == "Lesionado"
                ? "title text-left"
                : title == "¿Está seguro de generar esta cuenta de cobro?"
                ? "title_pago"
                : "title"
            }
          >
           {ReactHtmlParser(title)} 
          </div>
        )}

        <div
          className={
            path.pathname.includes("Entrenador/clase")
              ? title == "Lesionado"
                ? classesMsg + " text-left text colornuevaletra_entre my-3"
                : classesMsg + " text"
              : title == "Lesionado"
              ? classesMsg + " text-left text colornuevaletra my-0"
              : classesMsg + " text"
          }
          style={textSizeUp?{fontSize:textSizeUp,lineHeight:"110%"}:{ display: displayText }}
        >
          {ReactHtmlParser(text)}
        </div>
        <div
          className={title == "Lesionado" ? "scrolldolor" : "scrolldolornull"}
        >
          {title == "Lesionado"
            ? array.map((lesion) => {
                return (
                  <div className="d-row ">
                    <div className="d-flex">
                      <div
                        className={
                          path.pathname.includes("Entrenador/clase")
                            ? "circulorojolesion_clase "
                            : "circulorojolesion"
                        }
                      ></div>
                      <div className="text-left title ">
                        {lesion.part == 8
                          ? "Lesión Abdomen"
                          : lesion.part == 9
                          ? "Lesión Ingle Cadera"
                          : lesion.part == 1
                          ? "Lesión Cuello"
                          : lesion.part == 3
                          ? "Lesión hombro derecho"
                          : lesion.part == 5
                          ? "Lesión codo derecho"
                          : lesion.part == 7
                          ? "Lesión Mano derecha"
                          : lesion.part == 2
                          ? "Lesión hombro izquierdo"
                          : lesion.part == 4
                          ? "Lesión codo izquierdo"
                          : lesion.part == 6
                          ? "Lesión Mano izquierda"
                          : lesion.part == 10
                          ? "Lesión rodilla izquierda"
                          : lesion.part == 11
                          ? "Lesión rodilla derecha"
                          : lesion.part == 12
                          ? "Lesión pie izquierdo"
                          : lesion.part == 13
                          ? "Lesión pie derecho"
                          : lesion.part == 14
                          ? "Lesión columna alta"
                          : lesion.part == 17
                          ? "Lesión lumbares"
                          : lesion.part == 15
                          ? "Lesión gluteós"
                          : lesion.part == 16
                          ? "Lesión columna baja"
                          : null}
                      </div>
                    </div>
                    <div
                      className={
                        path.pathname.includes("Entrenador/clase")
                          ? "text text-left colornuevaletra_entre my-2"
                          : "text  colornuevaletra my-0"
                      }
                    >
                      {lesion.summary}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        <textarea
          style={{ display: displayInput, width: "100%", marginTop: "5%" }}
          autoFocus
          rows="5"
          onInput={handleInput}
        ></textarea>
        <div className="botonesModalInfo my-1">
          {props.handleClick ? (
            <ButtonMob
              text={textButtonConfirmar}
              // className="btnAction  py-2"
              className={
                handleCancel  ? "btnAction-only  py-2" : "btnAction  py-2"
              }
              handleClick={handleClick}
            />
          ) : null}

          {handleCancel ? (
            <ButtonMob
              text="Cancelar"
              className="btnActionCancelar py-2"
              handleClick={() => handleCancel()}
            />
          ) : null}
          { props.handleReservar ? (
            <ButtonMob
              text={textButtonAction}
              className={backgroundReservarBorder}
              handleClick={() => handleReservar()}
            />
          ) : null}
        </div>
        {loader?<div className="d-flex justify-content-center mt-4"><BarLoader color="#8d97fd" width={100} /></div>:null}
      </div>
    </div>
  );
});
export const ModalInfoMembresias = React.forwardRef((props, ref) => {
  const title = props.title;
  const icono = props.icono;
  const text = props.text || "none";
  const array = props.array || null;
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  const handleCancel = props.handleCancel;
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const classcorrecta = props.classcorrecta || null;
  const navigate = useNavigate();

 


  // memberships/
  const [isLoading, setIsLoading] = useState(true);
  const [dataMemberships, setDataMemberships] = useState([]);
  useEffect(() => {
    async function memberships() {
      setIsLoading(true);
      await instance
        .get(`${NuevaAxiosURL}memberships/?ordering=fee&is_active=true`)
        .then((response) => {
          const data = response.data;
          //console.log(data, "data acces ---------------");
          setDataMemberships(data);
          for (const i of data) {
            dataMemberships[i.id] = {
              name: i.name,
              description: i.description.split("\r\n"), // convertimos la descripción en un array de strings
              price: i.fee,
              modoPago: i.payment_currency,
              pagoFrecuente: i.payment_frequency_time,
              tiempoPago: i.payment_frequency_time,
              active: i.is_active,
              corporate: i.is_corporate,
            };
            // console.log(info, "soy infoo");
          }
        })
        .catch((error) => {
          console.log(error, "Memberships Error");
        });
      setIsLoading(false);
    }
    setTimeout(() => memberships(), 2000); // Agregar tiempo de espera de 2 segundos
  }, []);

  //console.log(setDataMemberships);

  // Delimitador limitedMembershipsName
  const limitedMembershipsName = dataMemberships.slice(0, 4);
  // console.log(limitedMembershipsName)

  // Cadena de texto array
  const descriptions = limitedMembershipsName.map((membership) => {
    return membership.description.split("\r\n");
  });
  const objeto = {
    containPlan: descriptions,
  };

  //console.log(objeto);
  //const membershipArray = description.split(", ");
  //console.log(membershipArray)

  //console.log(description);
  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
    padding: "2%",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  // Slider
  SwiperCore.use([Virtual, Navigation, Pagination]);

  const [slides, setSlides] = useState(
    Array.from({ length: 10 }).map((index) => ``)
  );

  // Membresias
  const [selectedMembership, setSelectedMembership] = useState(null);

  //console.log(selectedMembership, "soy selected membership");
  return (
    <></>
    //   <div
    //     ref={ref}
    //     className="modalInfo justify-content-center align-items-center"
    //     style={styleModal}
    //   >
    //     <div
    //       style={{ padding: "4vmin 2%", width: "100%", height: "100%" }}
    //       className={"modalInfoContenido"}
    //     >
    //       <div
    //         className=""
    //         style={{
    //           position: "absolute",
    //           top: "5%",
    //           right: "5%",
    //           fontWeight: "bold",
    //           fontSize: "2.5vmin",
    //           color: " #9DACBD",
    //           cursor: "pointer",
    //         }}
    //         onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
    //       >
    //         <i class="bi bi-x-circle"></i>
    //       </div>
    //       <div
    //         style={{
    //           position: "absolute",
    //           bottom: "0%",
    //           right: "7%",
    //         }}
    //       >
    //         <img
    //           style={{ width: "30vmin" }}
    //           src={process.env.PUBLIC_URL + "/assets/img/logooutback.png"}
    //         />
    //       </div>

    //       <div
    //         style={{
    //           color: "var(--elevvaTextTitle)",
    //           justifyContent: "center",
    //           padding: "0vmin 8vmin",
    //         }}
    //         className={"title  d-flex my-auto"}
    //       >
    //         <div
    //           className="d-flex"
    //           style={{
    //             flexDirection: "column",
    //             justifyContent: "center",
    //           }}
    //         >
    //           <div className=" d-flex tabulete-active mx-auto">
    //             <div
    //               className="centraNumberSteps fw-bold"
    //               style={{ fontSize: "1.5vmin", width: "10px", color: "#ffff" }}
    //             >
    //               1
    //             </div>
    //           </div>
    //         </div>

    //         <div
    //           className="linememberstep mx-1"
    //           style={{
    //             verticalAlign: "middle",
    //             width: "11vmin",
    //           }}
    //         >
    //           <hr />
    //         </div>
    //         <div
    //           className="d-flex"
    //           style={{ flexDirection: "column", justifyContent: "center" }}
    //         >
    //           <div className=" d-flex tabulete mx-auto">
    //             <div
    //               className="centraNumberSteps  fw-bold"
    //               style={{ fontSize: "1.5vmin" }}
    //             >
    //               2
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           className="linememberstep mx-1"
    //           style={{
    //             verticalAlign: "middle",
    //             width: "11vmin",
    //           }}
    //         >
    //           <hr />
    //         </div>
    //         <div
    //           className="d-flex"
    //           style={{ flexDirection: "column", justifyContent: "center" }}
    //         >
    //           <div className=" d-flex tabulete mx-auto">
    //             <div
    //               className="centraNumberSteps  fw-bold"
    //               style={{ fontSize: "1.5vmin" }}
    //             >
    //               3
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div
    //         style={{
    //           color: "var(--elevvaTextTitle)",
    //           justifyContent: "center",
    //           padding: "0vmin 8vmin",
    //         }}
    //         className={"title  d-flex my-auto"}
    //       >
    //         <div
    //           className="d-flex"
    //           style={{
    //             flexDirection: "column",
    //             justifyContent: "center",
    //           }}
    //         >
    //           <div className=" d-flex  mx-auto">
    //             <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
    //               Confirma tu pago
    //             </div>
    //           </div>
    //         </div>

    //         <div
    //           className="linememberstep"
    //           style={{
    //             verticalAlign: "middle",
    //             width: "4.5vmin",
    //           }}
    //         ></div>
    //         <div
    //           className="d-flex"
    //           style={{ flexDirection: "column", justifyContent: "center" }}
    //         >
    //           <div className=" d-flex  mx-auto">
    //             <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
    //               Selecciona
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           className="linememberstep"
    //           style={{
    //             verticalAlign: "middle",
    //             width: "6.0vmin",
    //           }}
    //         ></div>
    //         <div
    //           className="d-flex"
    //           style={{ flexDirection: "column", justifyContent: "center" }}
    //         >
    //           <div className=" d-flex  mx-auto">
    //             <div className=" d-flex " style={{ fontSize: "1.7vmin" }}>
    //               Disfruta tu plan
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       <div
    //         style={{
    //           fontSize: "3vmin",
    //           color: "var(--elevvaTextTitle)",
    //           justifyContent: "center",
    //           padding: "0vmin 8vmin",
    //           marginTop: "2vmin",
    //         }}
    //         className={"title  mb-0 d-flex"}
    //       >
    //         No pares de entrenar /{" "}
    //         <strong className="me-1">Selecciona tu plan</strong>
    //       </div>
    //       <div
    //         style={{
    //           marginBottom: "3vmin",
    //         }}
    //       >
    //         {" "}
    //         <span
    //           style={{
    //             fontSize: "2vmin",
    //             color: "var(--elevvaTextTitle)",
    //             fontWeight: "600",
    //             padding: "0vmin 8vmin",
    //           }}
    //         >
    //           Aprovecha nuestros planes de pago y obtén hasta un 40% de descuento

    //         </span>
    //       </div>
    //       {/* <Swiper
    //       slidesPerView={3}
    //       centeredSlides={true}
    //       spaceBetween={30}
    //       navigation={true}
    //       virtual> */}

    //       {/* <SwiperSlide> */}

    //       <div
    //         className="d-flex  mx-auto  "
    //         style={{
    //           padding: "1vmin",
    //           justifyContent: "center",
    //           margin: "1vmin 0 ",
    //           padding: "0% 1%",
    //         }}
    //       >
    //         {isLoading ? (
    //           <div className="spinner-container">
    //             <HashLoader color="#f3f4fe" size={90} />
    //           </div>
    //         ) : (
    //           <>
    //             <div
    //               className={

    //                 "direcccionately col d-flex resplandor"
    //               }
    //               style={

    //                 {
    //                   padding: "0 1%",
    //                   flexDirection: "column",
    //                   justifyContent: "center",
    //                   borderLeft: "1px solid #C9C9C9",
    //                   borderRadius: "20px",
    //                 }
    //               }
    //             >
    //               <div
    //                 className="d-flex w-100"
    //                 style={{ justifyContent: "center", flexDirection: "column" }}
    //               >
    //                 {imgMembresias.map(e => (
    //                   <div>
    //                     <img style={{ maxWidth: "25vmin" }} src={e.img} />{" "}
    //                   </div>
    //                 ))}
    //                 <div className="mt-1">
    //                   <span
    //                     className="fw-bold "
    //                     style={{
    //                       fontSize: "2.5vmin",
    //                       color: "var(--elevvaTextTitle)",
    //                     }}
    //                   // key={e.id}
    //                   >
    //                     {"Eleva a tu medida"}
    //                   </span>
    //                   {/* {index == 0 ? ( */}
    //                   <div style={{ padding: "0 6%" }}>
    //                     <span
    //                       className="fw-bold "
    //                       style={{
    //                         fontSize: "2.5vmin",
    //                         color: "var(--elevvaTextTitle)",
    //                       }}
    //                     >
    //                     </span>
    //                     <br />
    //                     <span style={{ fontSize: "1.3vmin" }}>
    //                       Con nuestro sistema de créditos, podrás reservar y tomar
    //                       clases personalizadas o grupales Online, el día y la
    //                       hora que desees, completamente a tu medida.
    //                     </span>
    //                     <br></br>

    //                     <div
    //                       style={{
    //                         color: "var(--elevvaTextTitle05)",
    //                         fontSize: "1.5vmin",

    //                         margin: "1vmin 0",
    //                       }}
    //                     >
    //                       {/* {e.savingPlan} */}
    //                       {"Vigencia 7 días"}
    //                     </div>

    //                     <div style={{ fontSize: "1.5vmin" }}>
    //                       Clases personalizadas
    //                     </div>
    //                   </div>
    //                   {/* ) : null} */}
    //                   <span
    //                     className="fw-bold"
    //                     style={{
    //                       color: "var(--elevva)",
    //                       fontSize: "2.0vmin",
    //                     }}
    //                   >
    //                     {/* {index == 0 ? "Desde" : ""} $ {e.fee} */}
    //                     {"Desde $ "}{15.99}
    //                   </span>{" "}
    //                   <br></br>
    //                   {/* {index !== 0 ? ( */}
    //                   <span
    //                     style={{
    //                       color: "var(--elevvaTextTitle05)",
    //                       fontSize: "1.5vmin",
    //                     }}
    //                   >
    //                     {/* {e.savingPlan} */}
    //                   </span>
    //                   {/* ) : null} */}
    //                 </div>
    //               </div>

    //               <div
    //                 className="m-auto d-flex mt-3"
    //                 style={{
    //                   width: "auto",
    //                   flexDirection: "column",
    //                   rowGap: "0.7vmin",
    //                 }}
    //               >
    //                 {/* {e.containPlan.map((e, i) => ( */}
    //                 <div className="divIndividualTypes me-auto d-flex">
    //                   <span
    //                     className=""
    //                     style={{ fontSize: "1.7vmin", color: "var(--elevva)" }}
    //                   >
    //                     {/* <i class="bi bi-check-lg"></i> */}
    //                   </span>{" "}
    //                   <span
    //                     className="me-auto"
    //                     style={{ fontSize: "1.5vmin", whiteSpace: "nowrap" }}
    //                   >
    //                     {/* {e} */}
    //                   </span>
    //                 </div>
    //                 {/* ))} */}
    //               </div>

    //               <ButtonMob
    //                 // colorTextButton={index == 0 ? "var(--white)" : "var(--elevva)"}
    //                 // colorButton={index == 0 ? "var(--elevva)" : "transparent"}
    //                 borderbutton={`2px solid var(--elevva)`}
    //                 text={"Adquiere tus créditos"}
    //                 className="btnAction  py-2 spacebutton"
    //               //handleClick={() => navigate(`/Usuario/payment`)}
    //               />
    //             </div>
    //             {/* {membresias.map(img => ( */}
    //             <>
    //               {limitedMembershipsName.map(e => (
    //                 <div
    //                   key={e.id}
    //                   className={
    //                     // index == 0
    //                     //   ? "direcccionately col d-flex resplandor"
    //                     // : "direcccionately col d-flex"
    //                     "direcccionately col d-flex"
    //                   }
    //                   style={
    //                     // index == 0 || index == 1
    //                     //   ? {
    //                     //       padding: "0 1%",
    //                     //       flexDirection: "column",
    //                     //       justifyContent: "center",
    //                     //       borderRadius: "20px",
    //                     //     }
    //                     //   : {
    //                     //       padding: "0 1%",
    //                     //       flexDirection: "column",
    //                     //       justifyContent: "center",
    //                     //       borderLeft: "1px solid #C9C9C9",
    //                     //     }
    //                     {
    //                       padding: "0 1%",
    //                       flexDirection: "column",
    //                       justifyContent: "center",
    //                       borderLeft: "1px solid #C9C9C9",

    //                     }
    //                   }
    //                 >
    //                   <div
    //                     className="d-flex w-100"
    //                     style={{ justifyContent: "center", flexDirection: "column" }}
    //                   >
    //                     <div>
    //                       <img style={{ maxWidth: "25vmin" }} src={process.env.PUBLIC_URL + "/assets/img/ilustracion_1.png"} alt="" />{" "}
    //                     </div>
    //                     <div className="mt-1">
    //                       <span
    //                         className="fw-bold "
    //                         style={{
    //                           fontSize: "2.5vmin",
    //                           color: "var(--elevvaTextTitle)",
    //                         }}
    //                         key={e.id}
    //                       >
    //                         {e.name}
    //                       </span>
    //                       <br></br>
    //                       <div style={{ padding: "0 6%" }}>
    //                         <span
    //                           className="fw-bold "
    //                           style={{
    //                             fontSize: "2.5vmin",
    //                             color: "var(--elevvaTextTitle)",
    //                           }}
    //                         >
    //                           {/* {"Eleva a tu medida"} */}
    //                           <span
    //                             className="fw-bold"
    //                             style={{
    //                               color: "var(--elevva)",
    //                               fontSize: "2.0vmin",
    //                             }}
    //                           >
    //                             $ {e.fee}
    //                           </span>
    //                         </span>

    //                       </div>
    //                       <span
    //                         style={{
    //                           color: "var(--elevvaTextTitle05)",
    //                           fontSize: "1.5vmin",
    //                         }}
    //                       >
    //                         {"Vigencia 7 días"}
    //                         <br></br>

    //                         {/* {e.description} */}
    //                       </span>

    //                     </div>
    //                   </div>
    //                   {/* {objeto.containPlan.map((description, index) => ( */}
    //                   <div
    //                     className="m-auto d-flex mt-3"
    //                     style={{
    //                       width: "auto",
    //                       flexDirection: "column",
    //                       rowGap: "0.7vmin",
    //                     }}
    //                   >
    //                     <div className="divIndividualTypes me-auto d-flex">
    //                       <span
    //                         className=""
    //                         style={{ fontSize: "1.7vmin", color: "var(--elevva)" }}
    //                       >
    //                         {/* <i class="bi bi-check-lg"></i> */}
    //                       </span>{" "}
    //                       <p
    //                         className="me-auto"
    //                         style={{ fontSize: "1.5vmin", whiteSpace: "pre-line" }}
    //                       // key={index}
    //                       >
    //                         {/* {e} */}
    //                         {e.description}
    //                         {/* {description} */}
    //                       </p>
    //                     </div>
    //                   </div>
    //                   {/* ))} */}
    //                   <ButtonMob
    //                     colorTextButton={"var(--elevva)"}
    //                     colorButton={"transparent"}
    //                     borderbutton={`2px solid var(--elevva)`}
    //                     text={"Seleccionar plan"}
    //                     className="btnAction  py-2 spacebutton button-position"
    //                     //handleClick={() => navigate(`/Usuario/payment`)}
    //                     // handleClick={() => handleMembershipSelect(e)}
    //                     handleClick={() => {
    //                       setSelectedMembership(e);
    //                       navigate(`/Usuario/payment`);
    //                     }}

    //                   />
    //                 </div>
    //               ))}
    //             </>
    //             {/* ))} */}
    //           </>
    //         )}
    //       </div>
    //       {/* </SwiperSlide>
    //       </Swiper> */}
    //       {/* <Slider /> */}

    //     </div>
    //   </div>
  );
});
export const ModalInfoPlan = React.forwardRef((props, ref) => {
  const [plan, setplan] = useState([]);
  const [DataPlan, setDataPlan] = useState("");
  const [isLoading, setisLoading] = useState(true);

  const title = props.title;
  const icono = props.icono;
  const text = props.text || "none";
  const array = props.array || null;
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "fixed";
  const handleCancel = props.handleCancel;
  const idMembership=props.idMembership;
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const classcorrecta = props.classcorrecta || null;
 const zindex=props.zindex || "999999999999"
 const navigate = useNavigate();
  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
    zIndex:zindex
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  // Mostrar mi plan

  const idUser = localStorage.getItem("id");


  useEffect(() => {
    instance
      .get(`${NuevaAxiosURL}memberships/${idMembership}/detail`)
      .then((response) => {
        const data = response.data;
        const plan = data.description.split(`\r\n`)
        console.log(data);
        console.log(plan)
        setisLoading(false);
        setDataPlan(data);
        setplan(plan);
        // setMembershipsId(data);
        // setIsLoading(false); // establecer isLoading en false después de que se actualice membershipsId
      })
      .catch((error) => {
        if(error.response){
  
        console.log(error, "Memberships Error");}
      });
  }, [idMembership]);

  // Delimitador de Memberships
  // const filteredMemberships = membershipsId.filter(
  //   (membership) =>
  //     membership.name === "Básico" ||
  //     membership.name === "Gratuito" ||
  //     membership.name === "Pro" ||
  //     membership.name === "Elevva plus"
  // );

  // Encontrando user = memberships

  // filtrando solo id y members
  // const searchUsers = filteredMemberships.map(({ id, members }) => ({
  //   id,
  //   members,
  // }));
  // console.log(searchUsers, "array de id");

  // function buscarObjetoPorId(searchUsers, id) {
  //   for (let i = 0; i < searchUsers.length; i++) {
  //     if (searchUsers[i].members.includes(id)) {
  //       return { id: searchUsers[i].id, objeto: searchUsers[i] };
  //     }
  //   }
  //   return null;
  // }
  // buscarObjetoPorId(searchUsers, idUser);

  // useEffect(() => {
  //   function limite() {
  //     if (!isLoading && buscarObjetoPorId !== null) {
  //       // verificar si isLoading es false antes de llamar a buscarObjetoPorId
  //       console.log(buscarObjetoPorId(searchUsers, idUser));
  //       console.log("tengo cuenta");
  //     } else {
  //       console.log("soy nuevo");
  //     }
  //   }
  //   limite();
  // }, [searchUsers, isLoading]);

  // console.log(buscarObjetoPorId(searchUsers), "filter final");
  const classesMsg = props.classesMsg || "";
  return (
  <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      
      <div style={{ padding: "4vmin 0vmin" }} className={"modalInfoContenido_plan"}>

      {isLoading?<Loadingspinner customStyle="border" size="7vmin" widthContain={"10rem"}/>: plan.length>0? 
      <>
        {error == "none" || error == "true" ? (
          <div
            className=""
            style={{
              position: "absolute",
              top:laptop575.matches? "5%":"3%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div>
        ) : (
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div>
        )}

        <div
          style={{
            fontSize: "calc(1rem + 0.3vmin)",
            color: "var(--elevvaTextTitle)",
            justifyContent: "left",
            padding: "0vmin 8vmin",
          }}
          className={"title  mb-2 d-flex"}
        >
          <strong className="me-1">Mi</strong>
          plan
        </div>
<div id="miplanPopUp">
        <div
          className="d-flex mx-auto my-3 "
          style={{
            background: "#F4F0ED",
            padding:laptop575.matches? "3% 0%":"6% 0%",
            color: "var(--elevvaTextTitle)",
            borderRadius: "30px",
            maxWidth: laptop575.matches?"60%":"80%",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div>
            {" "}
            <img
              src={iconoFlechaElevva} style={{ width: "1.3rem", margin: "0.5rem 0 " }}
            />{" "}
          </div>
          <span style={{ fontSize: "calc(0.7rem + 0.3vmin)",fontWeight:"bold"}} >Plan de entrenamiento</span>
          <span
           
            style={{
              color: "var(--elevva)",
              fontSize: "calc(0.7rem + 0.3vmin)",
              fontWeight:"bold"
            }}
          >
            {DataPlan.name}
          </span>
          <span  style={{ fontSize: "calc(0.7rem + 0.3vmin)",}} >Diciembre 20 de 2022 / Enero 19 de 2023</span>
        </div>
        <div
          className=" d-flex flex-column flex-md-row mx-auto" style={{    padding: "0% 10%",}}
          
        >
        <div className="d-flex col-12 col-md-10 mx-auto" style={{
           
           justifyContent: "center",
           margin: "1vmin 0 ",
       
         }}> <div className="direcccionately col  " style={{ padding: "0 0%" }}>
            <span className="letrapequeñaProfilePlan">003</span>
            <span className=" letrapequeñaProfileDown_otherPlan" style={{fontWeight:"bold"}}>
              Clases grupales <br></br> restantes
            </span>
          </div>
          <div
            className="direcccionately_other col "
            style={laptop575.matches?{
              borderLeft: "1px solid gray",
              borderRight: "1px solid gray",
              padding: "0 0%",
            }:{
              borderLeft: "1px solid gray",
           
              padding: "0 0%",
            }}
          >
            <span className="letrapequeñaProfilePlan">008</span>
            <span className=" letrapequeñaProfileDown_otherPlan" style={{fontWeight:"bold"}}>
              Clases individuales <br></br> restantes
            </span>
          </div>
          <div
            className="direcccionately col "
            style={laptop575.matches?{
           
              padding: "0 0%",
            }:{
              borderLeft: "1px solid gray",
           
              padding: "0 0%",
            }}
          >
            <span className="letrapequeñaProfilePlan">028</span>
            <span className=" letrapequeñaProfileDown_otherPlan" style={{fontWeight:"bold"}}>
            Acceso a <br></br> videos
            </span>
          </div>
          </div>
        {/* <div className="d-flex col-12 col-md-4  " style={{
           
           justifyContent: "center",
           margin: "1vmin 0 ",
        
         }} >
        <div
            className="direcccionately_other col "
            style={{
              borderRight: "1px solid gray",
              padding: "0 0%",
            }}
          >
            <span className="letrapequeñaProfilePlan">028</span>
            <span className=" letrapequeñaProfileDown_otherPlan" style={{fontWeight:"bold"}}>
              Acceso a <br></br> videos
            </span>
          </div>

          <div className="direcccionately col " style={{ padding: "0 0%" }}>
            <span className="letrapequeñaProfilePlan">002</span>
            <span className=" letrapequeñaProfileDown_otherPlan" style={{fontWeight:"bold"}}>
              Asesoría <br></br> en nutrición
            </span>
          </div>

        </div> */}
         
         
        </div>
</div>
        <div
          className={classesMsg + " my-3"}
          style={{ color: "var(--elevvaTextTitle)", fontSize: "calc(0.7rem + 0.3vmin)",fontWeight:"bold" }}
        >
          Especificaciones de tu plan
         
        </div>
        <div
          className="d-flex mx-auto"
          style={{
            marginTop: "1vmin",
            marginBottom: "1vmin",
            justifyContent: "flex-start",
            flexDirection:"row",
            flexWrap: "wrap",
            width:"90%"
          }}
        >
          {plan.map((e, i) => (
            <div className=" px-1"   style={plan.length>1?{width:"50%",}:{width:"100%",}}>  <button key={i} style={{fontWeight:"bold"}} className="buttonIndividualTypesCom py-2">
              {e}
            </button> </div>
          
          ))}
        </div>
        <ButtonMob colorButton={"white"} sizeFont={'calc(0.7rem + 0.3vmin)'}
                colorTextButton={`var(--elevva)`}
                borderbutton={`1px solid var(--elevva)`} text="Resumen de compras" handleClick={() => laptop575.matches?navigate("/perfil"): navigate("/Transacciones")}/>
        </>:<> <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "5%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={handleCancel}
          >
            <i className="bi bi-x-circle"></i>
          </div><div className="mt-2" style={{
            fontSize: "calc(1rem + 0.3vmin)",
            color: "var(--elevvaTextTitle)",
            justifyContent: "left",
            padding: "0vmin 8vmin",
          }}><span >No tienes una membresía activa</span></div>
          <ButtonMob text="Entendido" handleClick={handleCancel}/>
          </>}
      </div>
       </div> );
});
export const ModalPhoto = React.forwardRef((props, ref) => {
  const title = props.title;
  const icono = props.icono;
  const text = props.text || "none";
  const array = props.array || null;
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  const handleCancel = props.handleCancel;
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const classcorrecta = props.classcorrecta || null;
  const plan = [
    "12 créditos de entrenamiento",
    "1 clase grupal gratis semanal",
    "Plan de entrenamiento",
    "Plan de alimentación",
    "Acceso a 1 video diario",
  ];

  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div style={{ padding: "0vmin 0vmin" }} className={"modalInfoContenido"}>
        <img
          style={{ width: "100%", borderRadius: "10px" }}
          src={process.env.PUBLIC_URL + "/assets/img/imagePopUp.jpg"}
        />
        <div
          className=""
          style={{
            position: "absolute",
            top: "3%",
            right: "5%",
            fontWeight: "bold",
            fontSize: "2.5vmin",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={handleCancellcargue ? handleCancellcargue : handleCancel}
        >
          <i className="bi bi-x-circle"></i>
        </div>

        <div
          className="d-flex mx-auto my-3 fw-600"
          style={{
            padding: "3% 0%",
            color: "var(--elevvaTextTitle)",
            borderRadius: "30px",

            textAlign: "center",
            fontSize: "3vmin",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span
            className="m-auto"
            style={{
              fontSize: "2.5vmin",
              lineHeight: "100%",
              maxWidth: "35vmin",
            }}
          >
            Más de 20 disciplinas disponibles{" "}
            <strong>en nuestra web y App</strong>
          </span>

          <button
            className="mx-auto mt-3 d-flex"
            style={{
              color: "var(--white)",

              bottom: "5%",
              right: "5%",
              padding: "1% 5%",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              verticalAlign: "middle",
              background: "var(--elevva)",
              borderRadius: "20px",
              border: "1px solid var(--elevva)",
              fontSize: "1.4vmin",
            }}
          >
            Inicia tu prueba de 3 días <i className="bi bi-chevron-right"></i>
          </button>
          <span
            style={{
              padding: "3% 0%",
              color: "var(--elevvaTextTitle)",
              borderRadius: "30px",
              fontSize: "1.3vmin",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            Gracias, Tal vez más tarde
          </span>
        </div>
      </div>
    </div>
  );
});

//Modal Beneficios elevva +!
export const ModalElevva = React.forwardRef((props, ref) => {
  const handleClose = props.handleClose;
  const title = props.title;
  const icono = props.icono;
  const text = props.text || "none";
  const array = props.array || null;
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  const handleCancel = props.handleCancel;
  const handleDolorClassLesion = props.handleDolorClassLesion || null;
  const handleCancellcargue = props.handleCancellcargue;
  const classcorrecta = props.classcorrecta || null;
  const plan = [
    "12 créditos de entrenamiento",
    "1 clase grupal gratis semanal",
    "Plan de entrenamiento",
    "Plan de alimentación",
    "Acceso a 1 video diario",
  ];

  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error == "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text == "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text == "input") {
    displayText = "none";
    displayInput = "block";
  }

  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div style={{ padding: "0vmin 0vmin" }} className={"modalInfoContenido"}>
        <img
          style={{ width: "100%", borderRadius: "10px" }}
          src={process.env.PUBLIC_URL + "/assets/img/ilustracion_1.png"}
        />
        <div
          className=""
          style={{
            position: "absolute",
            top: "3%",
            right: "5%",
            fontWeight: "bold",
            fontSize: "2.5vmin",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <i className="bi bi-x-circle"></i>
        </div>

        <div
          className="d-flex mx-auto my-3 fw-600"
          style={{
            padding: "3% 0%",
            color: "var(--elevvaTextTitle)",
            borderRadius: "30px",

            textAlign: "center",
            fontSize: "3vmin",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span
            className="m-auto"
            style={{
              fontSize: "2.5vmin",
              lineHeight: "100%",
              maxWidth: "35vmin",
            }}
          >
            ¡Adquiere todos los beneficios de <strong>elevva +!</strong>
          </span>
          <span
            style={{
              padding: "3% 0%",
              color: "var(--elevvaTextTitle)",
              borderRadius: "30px",
              fontSize: "2vmin",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            Inicia tu prueba gratuita de 7 días
          </span>
          {/* <button
            className="mx-auto mt-3 d-flex"
            style={{
              color: "var(--white)",

              bottom: "5%",
              right: "5%",
              padding: "1% 5%",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              verticalAlign: "middle",
              background: "var(--elevva)",
              borderRadius: "20px",
              border: "1px solid var(--elevva)",
              fontSize: "1.4vmin",
            }}
            handleClick={() => {
              window.location.href = "/Usuario/Inicio"
            }}
          >
            Conoce más <i className="bi bi-chevron-right"></i>
          </button> */}
          <div className="d-flex align-items-center justify-content-center">
            <ButtonMob
              colorButton={`var(--elevva)`}
              className="mx-2 sizebuttonLarge"
              text={"Entendido"}
              handleClick={() => {
                window.location.href = "/Usuario/groupals";
              }}
            />
          </div>
          {/* <Link to={`/createUser/${type}`}> */}
        </div>
      </div>
    </div>
  );
});

//Modal creacion de cuenta
export const ModalCuenta = React.forwardRef((props, ref) => {
  const handleClose = props.handleClose;

  const text = props.text || "none";
  const text2 = props.text2 || "none";
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error === "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text === "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text === "input") {
    displayText = "none";
    displayInput = "block";
  }

  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div  className={"modalInfoContenido"}>
        <div
          className=""
          style={{
            position: "absolute",
            top: "3%",
            right: "5%",
            fontWeight: "bold",
            fontSize: "calc(1rem + 0.5vw)",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <i className="bi bi-x-circle"></i>
        </div>

        <div
          className="d-flex mx-auto  fw-600 text-center  flex-column justify-content-center gap-3 gap-md-4"
          style={{
           
            color: "var(--elevvaTextTitle)",
            borderRadius: "30px",
            fontSize: "calc(0.5rem + 0.8vw)",
            
         
          }}
        >
          <div className="d-flex justify-content-center ">
            <i className="bi bi-check-lg align-self-center modalcitoOk"></i>
          </div>
         
          <span
            className="m-auto"
            style={{
              fontSize: "calc(0.9rem + 0.5vw)",
              lineHeight: "100%",
             
            }}
          >
          {ReactHtmlParser(text)}
            
          </span>
          <div className="modal-body">
            <span
              className="text-color-subtitle m-0"
              style={{
                lineHeight: "100%",
                fontSize: "calc(0.9rem + 0.5vw)",
              }}
            >
            {ReactHtmlParser(text2)}
             
            </span>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <ButtonMob
              colorButton={`var(--elevva)`}
              className="mx-2 sizebuttonLarge my-0"
              text={"Entendido"}
              handleClick={() => {
                window.location.href = "/Usuario/welcometoElevva";
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export const ModalCuentaError = React.forwardRef((props, ref) => {
  const handleClose = props.handleClose;

  const text = props.text || "none";
  const error = props.error || "none";
  const display = props.display || "flex";
  const position = props.position || "absolute";
  function handleClick(event) {
    props.handleClick();
  }
  function handleInput(e) {
    props.handleInput(e);
  }
  let displayError = "block";
  if (error === "none") {
    displayError = "none";
  }
  let displayText = "block";
  if (text === "none") {
    displayText = "none";
  }
  let displayInput = "none";
  let styleModal = {
    display: display,
    position: position,
    whiteSpace: "pre-line",
  };
  if (text === "input") {
    displayText = "none";
    displayInput = "block";
  }

  return (
    <div
      ref={ref}
      className="modalInfo justify-content-center align-items-center"
      style={styleModal}
    >
      <div style={{ padding: "0vmin 0vmin" }} className={"modalInfoContenido"}>
        <div
          className=""
          style={{
            position: "absolute",
            top: "3%",
            right: "5%",
            fontWeight: "bold",
            fontSize: "2.5vmin",
            color: " #9DACBD",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <i className="bi bi-x-circle"></i>
        </div>

        <div
          className="d-flex mx-auto my-3 fw-600"
          style={{
            padding: "3% 0%",
            color: "var(--elevvaTextTitle)",
            borderRadius: "30px",

            textAlign: "center",
            fontSize: "3vmin",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <span
            className="m-auto"
            style={{
              fontSize: "2.5vmin",
              lineHeight: "100%",
              maxWidth: "35vmin",
            }}
          >
            Hay un <strong>error</strong>
            en el registro de
            <strong>tu contraseña</strong>
          </span>
          {/* <button
            className="mx-auto mt-3 d-flex"
            style={{
              color: "var(--white)",

              bottom: "5%",
              right: "5%",
              padding: "1% 5%",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              verticalAlign: "middle",
              background: "var(--elevva)",
              borderRadius: "20px",
              border: "1px solid var(--elevva)",
              fontSize: "1.4vmin",
            }}
            handleClick={() => {
              window.location.href = "/Usuario/Inicio"
            }}
          >
            Conoce más <i className="bi bi-chevron-right"></i>
          </button> */}
          <p>
            Debe tener al menos 8 caracteres y recomendamos incluir letras
            mayúsculas y miúsculas, números no consecutivos y/o otros caracteres
          </p>
          <div className="d-flex align-items-center justify-content-center">
            <ButtonMob
              colorButton={`var(--elevva)`}
              className="mx-2 sizebuttonLarge"
              text={"Entendido"}
              onClick={handleClose}
            />
          </div>
          {/* <Link to={`/createUser/${type}`}> */}
        </div>
      </div>
    </div>
  );
});

export const SetModalError = (props) => {
  const classes = props.className;
  const msgError =
    props.msg ||
    "Algo nos acaba de suceder, por favor intentalo nuevamente en unos minutos";
  const titleError = props.title || "Algo salio mal";
  const handleClick = props.handleClick;
  return (
    <ModalInfo
      error="false"
      handleClick={handleClick}
      handleCancellcargue={handleClick}
      title={titleError}
      text={msgError}
    />
  );
};

// Textarea









export const fancyTimeFormat = (mins) => {
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  if (hours < 10) hours = ("0" + hours).slice(-2);
  return `${hours}:${("0" + minutes).slice(-2)}`;
};


export function metricFormat(userId, title, metric, totalCustomers) {
  if (totalCustomers) {
    let userSelected = totalCustomers.filter((user) => user.id == userId)[0];

    if (title && title == "1") {
      //PESO
      let valorEvaluate = metric / Math.pow(userSelected.height / 100, 2);
      console.log(valorEvaluate);
      if (valorEvaluate < 18.5) {
        return "BAJO";
      } else if (valorEvaluate > 18.4 && valorEvaluate < 25) {
        return "NORMAL";
      } else if (valorEvaluate > 24.9 && valorEvaluate < 30) {
        return "ALTO";
      } else return "MUY ALTO";
    }
    if (title && title == "2") {
      //IMC
      let valorEvaluate = metric;
      console.log(valorEvaluate);
      if (valorEvaluate < 18.5) {
        return "BAJO";
      } else if (valorEvaluate > 18.4 && valorEvaluate < 25) {
        return "NORMAL";
      } else if (valorEvaluate > 24.9 && valorEvaluate < 30) {
        return "ALTO";
      } else return "MUY ALTO";
    }
    if (title && title == "3") {
      //`Grasa corporal`
      let valorEvaluate = metric;
      if (userSelected?.gender == "Masculino") {
        if (userSelected?.age < 40) {
          if (valorEvaluate < 8) return "BAJO";
          else if (valorEvaluate < 21 && valorEvaluate > 7) return "NORMAL";
          else if (valorEvaluate < 26 && valorEvaluate > 19) return "ALTO";
          else return "MUY ALTO";
        } else if (userSelected?.age > 39 && userSelected?.age < 60) {
          if (valorEvaluate < 11) return "BAJO";
          else if (valorEvaluate < 23 && valorEvaluate > 10) return "NORMAL";
          else if (valorEvaluate < 29 && valorEvaluate > 21) return "ALTO";
          else return "MUY ALTO";
        } else if (userSelected?.age > 59) {
          if (valorEvaluate < 13) return "BAJO";
          else if (valorEvaluate < 26 && valorEvaluate > 12) return "NORMAL";
          else if (valorEvaluate < 31 && valorEvaluate > 24) return "ALTO";
          else return "MUY ALTO";
        }
      } else {
        if (userSelected?.age < 40) {
          if (valorEvaluate < 21) return "BAJO";
          else if (valorEvaluate < 34 && valorEvaluate > 20) return "NORMAL";
          else if (valorEvaluate < 40 && valorEvaluate > 32) return "ALTO";
          else return "MUY ALTO";
        } else if (userSelected?.age > 39 && userSelected?.age < 60) {
          if (valorEvaluate < 23) return "BAJO";
          else if (valorEvaluate < 36 && valorEvaluate > 22) return "NORMAL";
          else if (valorEvaluate < 41 && valorEvaluate > 34) return "ALTO";
          else return "MUY ALTO";
        } else if (userSelected?.age > 59) {
          if (valorEvaluate < 24) return "BAJO";
          else if (valorEvaluate < 37 && valorEvaluate > 23) return "NORMAL";
          else if (valorEvaluate < 43 && valorEvaluate > 35) return "ALTO";
          else return "MUY ALTO";
        }
      }
    }
    if (title && title == "5") {
      //`Grasa subcutánea`
      let valorEvaluate = metric;
      if (userSelected?.gender == "Masculino") {
        if (valorEvaluate < 1) return "BAJO";
        else if (valorEvaluate < 4 && valorEvaluate > 0) return "NORMAL";
        else if (valorEvaluate < 21 && valorEvaluate > 3) return "ALTO";
        else return "MUY ALTO";
      } else {
        if (valorEvaluate < 1) return "BAJO";
        else if (valorEvaluate < 13 && valorEvaluate > 0) return "NORMAL";
        else if (valorEvaluate < 29 && valorEvaluate > 12) return "ALTO";
        else return "MUY ALTO";
      }
    }
    if (title && title == "6") {
      //`Grasa visceral`
      let valorEvaluate = metric;
      if (valorEvaluate < 12) return "NORMAL";
      else return "MUY ALTO";
    }
    if (title && title == "7") {
      //`Agua corporal`
      let valorEvaluate = metric;
      if (userSelected?.gender == "Masculino") {
        if (valorEvaluate < 51) return "BAJO";
        else if (valorEvaluate < 66 && valorEvaluate > 50) return "NORMAL";
        else return "ALTO";
      } else {
        if (valorEvaluate < 46) return "BAJO";
        else if (valorEvaluate < 61 && valorEvaluate > 45) return "NORMAL";
        else return "ALTO";
      }
    }
    if (title && title == "8") {
      //`Músculo esquelético`
      let valorEvaluate = metric;
      if (userSelected?.gender == "Masculino") {
        if (userSelected?.age < 40) {
          if (valorEvaluate < 33.3) return "BAJO";
          else if (valorEvaluate < 39.4 && valorEvaluate > 33.2)
            return "NORMAL";
          else if (valorEvaluate < 45 && valorEvaluate > 39.3) return "ALTO";
          else return "MUY ALTO";
        } else if (userSelected?.age > 39 && userSelected?.age < 60) {
          if (valorEvaluate < 33.1) return "BAJO";
          else if (valorEvaluate < 39.2 && valorEvaluate > 33.0)
            return "NORMAL";
          else if (valorEvaluate < 43.9 && valorEvaluate > 39.1) return "ALTO";
          else return "MUY ALTO";
        } else if (userSelected?.age > 59) {
          if (valorEvaluate < 32.9) return "BAJO";
          else if (valorEvaluate < 39 && valorEvaluate > 32.8) return "NORMAL";
          else if (valorEvaluate < 43.7 && valorEvaluate > 38.9) return "ALTO";
          else return "MUY ALTO";
        }
      } else {
        if (userSelected?.age < 40) {
          if (valorEvaluate < 24.3) return "BAJO";
          else if (valorEvaluate < 30.4 && valorEvaluate > 24.2)
            return "NORMAL";
          else if (valorEvaluate < 35.4 && valorEvaluate > 30.3) return "ALTO";
          else return "MUY ALTO";
        } else if (userSelected?.age > 39 && userSelected?.age < 60) {
          if (valorEvaluate < 24.1) return "BAJO";
          else if (valorEvaluate < 30.2 && valorEvaluate > 24) return "NORMAL";
          else if (valorEvaluate < 35.2 && valorEvaluate > 30.1) return "ALTO";
          else return "MUY ALTO";
        } else if (userSelected?.age > 59) {
          if (valorEvaluate < 23.9) return "BAJO";
          else if (valorEvaluate < 30 && valorEvaluate > 23.8) return "NORMAL";
          else if (valorEvaluate < 35 && valorEvaluate > 29.9) return "ALTO";
          else return "MUY ALTO";
        }
      }
    }
    if (title && title == "9") {
      //`Masa esquelética`
      let valorEvaluate = metric;
      if (userSelected?.gender == "Masculino") {
        if (valorEvaluate < 6 && valorEvaluate > 2) return "NORMAL";
        else return "ALTO";
      } else {
        if (valorEvaluate < 4 && valorEvaluate > 1) return "PROMEDIO";
        else return "ALTO";
      }
    }
    if (title && title == "10") {
      //`Masa muscular`
      let valorEvaluate = metric;
      if (userSelected?.gender == "Masculino") {
        if (userSelected?.age < 31) {
          if (valorEvaluate < 43) return "BAJO";
          else if (valorEvaluate < 57 && valorEvaluate > 42) return "NORMAL";
          else return "ALTO";
        } else if (userSelected?.age > 30 && userSelected?.age < 61) {
          if (valorEvaluate < 40) return "BAJO";
          else if (valorEvaluate < 51 && valorEvaluate > 39) return "NORMAL";
          else return "ALTO";
        } else if (userSelected?.age > 59) {
          if (valorEvaluate < 38) return "BAJO";
          else if (valorEvaluate < 58 && valorEvaluate > 37) return "NORMAL";
          else return "ALTO";
        }
      } else {
        if (userSelected?.age < 31) {
          if (valorEvaluate < 35) return "BAJO";
          else if (valorEvaluate < 42 && valorEvaluate > 34) return "NORMAL";
          else return "ALTO";
        } else if (userSelected?.age > 30 && userSelected?.age < 61) {
          if (valorEvaluate < 33) return "BAJO";
          else if (valorEvaluate < 39 && valorEvaluate > 32) return "NORMAL";
          else return "ALTO";
        } else if (userSelected?.age > 59) {
          if (valorEvaluate < 28) return "BAJO";
          else if (valorEvaluate < 34 && valorEvaluate > 27) return "NORMAL";
          else return "ALTO";
        }
      }
    }
    if (title && title == "11") {
      //`PROTEINA`
      let valorEvaluate = metric;
      if (valorEvaluate > 20) return "MUY ALTO";
      else if (valorEvaluate < 16) return "BAJO";
      else return "NORMAL";
    } else return "";
  } else return;
}
export function knokinjurie(lesion) {
  if (lesion == 8) return "Lesión Abdomen";
  else if (lesion == 9) return "Lesión Ingle Cadera";
  else if (lesion == 1) return "Lesión Cuello";
  else if (lesion == 3) return "Lesión hombro derecho";
  else if (lesion == 5) return "Lesión codo derecho";
  else if (lesion == 7) return "Lesión Mano derecha";
  else if (lesion == 2) return "Lesión hombro izquierdo";
  else if (lesion == 4) return "Lesión codo izquierdo";
  else if (lesion == 6) return "Lesión Mano izquierda";
  else if (lesion == 10) return "Lesión rodilla izquierda";
  else if (lesion == 11) return "Lesión rodilla derecha";
  else if (lesion == 12) return "Lesión pie izquierdo";
  else if (lesion == 13) return "Lesión pie derecho";
  else if (lesion == 14) return "Lesión columna alta";
  else if (lesion == 17) return "Lesión lumbares";
  else if (lesion == 15) return "Lesión gluteós";
  else if (lesion == 16) return "Lesión columna baja";
  else return null;
}
