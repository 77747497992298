import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./App.css";
import LoginCustomerConvenio from "./components/LoginConvenio/loginConvenio";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import i18n from "./i18n";
import { Buffer } from "buffer";
import CreateaccountMember from "./components/createAccountMembership/createaccountMember";
import RecoverPassword from "./components/recoverPassword/recoverPass";
import Dashboard from "./components/dashboard/dashboard";
import NavBar from "./components/Navbar/navBar";
import { ButtonMob, ModalInfo, instance } from "./utils/utils";
import icoPositivo from "./assets/newIcons/ICO-POSITIVO.svg";
import Graphics from "./components/graphics/graphics";
import Notifications from "./components/notifications/notifications";

function App() {
  const { t } = useTranslation();
  const NameCustomer = t("cover_start_login_customer");
  const NameTrainer = t("cover_start_login_trainer");

  const [location_all_information, setlocation_all_information] =
    useState(null);
  const [modal, setModal] = useState(null);
  const [confirm, setModalConfirm] = useState(null);
  const [ErroraGregado, seterroraGregado] = useState(null);
  const [displayCorrect, setDisplayCorrect] = useState(null);
  useEffect(() => {
    axios.get("https://ipwhois.app/json/").then((res) => {
      const data_location = res.data;
      if (data_location) {
        if (data_location.continent === "South America")
          i18n.changeLanguage("es");
        else i18n.changeLanguage("en");
        setlocation_all_information(data_location);
      }
    });
  }, []);
  global.Buffer = Buffer;
  const hideModal = () => {
    setModal(null);
  };
  useEffect(() => {
    if (displayCorrect) handlePasswordUpdate();
  }, [displayCorrect]);
  function Change_password(event) {
    let change1 = event.target[0].value;
    let change2 = event.target[1].value;

    var formData = new FormData();
    formData.append("password", change1.trim()); //pass1
    formData.append("password2", change2.trim()); //pass1

    instance
      .post(`users/auth/change-password/`, formData)
      .then((res) => {
        if (res.data) {
          setModalConfirm(null);
          let styleModal = {
            display: "flex",
            position: "fixed",
            zIndex: 999,
            whiteSpace: "pre-line",
          };
          setModal(
            <div
              className="popUpPassword justify-content-center align-items-center"
              style={styleModal}
            >
              <div
                className="modalInfoContenido"
                style={{
                  background: "white",
                  borderRadius: "20px",
                  boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
                  padding: "2% 6%",
                }}
              >
                <div
                  className=""
                  style={{
                    position: "absolute",
                    top: "5%",
                    right: "1%",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    color: " #9DACBD",
                    cursor: "pointer",
                  }}
                  onClick={() => setModal(null)}
                >
                  <i className="bi bi-x-circle"></i>
                </div>
                <div
                  className=" mb-3 py-3"
                  style={{
                    lineHeight: "100%",
                    textAlign: "center",

                    minWidth: "calc(18rem + 1vmin)",

                    color: "var(--elevvabutonLogin_User)",
                  }}
                >
                  <br />
                  Tu contraseña ha <br /> sido modificada <br /> correctamente.
                  <br />
                </div>
                <form id="form-password-change" onSubmit={hideModal}>
                  <div className="col-12">
                    <div className="col-12 btnAction_pruebas letraspopfi2">
                      <ButtonMob
                        colorButton={"var(--elevvabutonLogin_User)"}
                        text="Confirmar"
                        className={"mb-3 mt-3 letraspopfi2actualizar"}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          );
        }
      })
      .catch((err) => {
        let msg_error = err.response.data;
        if (msg_error) {
          console.log(msg_error);
          seterroraGregado(msg_error);

          setDisplayCorrect("flex");
          setTimeout(() => {
            setDisplayCorrect("none");
          }, 5000);
        }
      });

    event.preventDefault();
  }
  function handlePasswordUpdate() {
    let styleModal = {
      display: "flex",
      position: "fixed",
      zIndex: 999,
      whiteSpace: "pre-line",
    };
    setModal(
      <div
        className="popUpPassword justify-content-center align-items-center"
        style={styleModal}
      >
        <div
          className="modalInfoContenido"
          style={{
            background: "white",
            borderRadius: "20px",
            boxShadow: "0px 5px 5px 4px rgba(157,172,189,0.7)",
            padding: "2% 6%",
          }}
        >
          <div
            className=""
            style={{
              position: "absolute",
              top: "5%",
              right: "1%",
              fontWeight: "bold",
              fontSize: "1.5rem",
              color: " #9DACBD",
              cursor: "pointer",
            }}
            onClick={() => setModal(null)}
          >
            <i className="bi bi-x-circle"></i>
          </div>
          <div
            className=" mb-3 py-3"
            style={{
              lineHeight: "100%",
              textAlign: "center",

              color: "var(--elevvabutonLogin_User)",
            }}
          >
            Actualiza tu contraseña
          </div>
          <form
            id="form-password-change"
            onSubmit={(e) => {
              Change_password(e);
            }}
          >
            {" "}
            <input
              className="form-control input-style-elevva "
              style={{
                minWidth: "calc(18rem + 1vmin)",
                fontSize: "calc(.4rem + .4vw)",
              }}
              name="tester"
              placeholder="Contraseña"
              required
            />
            <br />
            <input
              className="form-control input-style-elevva "
              style={{
                minWidth: "calc(18rem + 1vmin)",
                fontSize: "calc(.4rem + .4vw)",
              }}
              name="tester"
              placeholder="Repite tu contraseña"
              required
            />
            <div
              id="passmail_error2"
              className=""
              style={{
                display: `${displayCorrect}`,
                color: "var(--elevva)",
                maxWidth: "calc(18rem + 1vmin)",
                fontSize: "calc(.3rem + .3vw)",
              }}
            >
              {ErroraGregado ? ErroraGregado.password : null}
            </div>
            <div className="col-12">
              <div className="col-12 btnAction_pruebas letraspopfi2">
                <ButtonMob
                  colorButton={"var(--elevvabutonLogin_User)"}
                  text="Confirmar"
                  className={"mb-3 mt-3 letraspopfi2actualizar"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <div
        className="col-12 d-md-flex"
        style={{
          height: "100vh",
        }}
      >
        {modal}
        <NavBar
          path={"Nav"}
          typeCustomer={NameCustomer}
          handlePasswordUpdate={handlePasswordUpdate}
        />
        <Routes>
          <Route
            exact
            path="/"
            element={<LoginCustomerConvenio elevvaT={t} />}
          />
          <Route
            exact
            path={`selectMembership/Usuario`}
            element={<CreateaccountMember type={NameCustomer} elevvaT={t} />}
          />
          <Route
            exact
            path="/recuperar"
            element={<RecoverPassword elevvaT={t} />}
          />
          <Route exact path="/dashboard" element={<Dashboard elevvaT={t} />} />
          <Route exact path="/graphics" element={<Graphics elevvaT={t} />} />
          <Route
            exact
            path="/notifications"
            element={<Notifications elevvaT={t} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
